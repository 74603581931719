import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'

if (location.hostname == '160.251.17.184') {
	axios.defaults.baseURL = 'http://160.251.17.184/workmanager-api'
} else if (location.hostname == 'shift.yushoku-kaigoshoku.com') {
	axios.defaults.baseURL = 'https://shift.yushoku-kaigoshoku.com/workmanager-api'
} else {
	axios.defaults.baseURL = 'http://localhost/workmanager-api'
}

axios.interceptors.request.use(function (config) {
	if (store.getters.token) {
		axios.defaults.headers.common['Authorization'] = 'Bearer ' + store.getters.token
		config.headers.Authorization = 'Bearer ' + store.getters.token
	} else {
		axios.defaults.headers.common['Authorization'] = ''
		config.headers.Authorization = ''
	}
	return config
}, function (error) {
	return Promise.reject(error)
})

createApp(App).use(store).use(router, axios).mount('#app')
