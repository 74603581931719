import { createRouter, createWebHashHistory } from 'vue-router'
import store from '@/store'

const routes = [
	{ path: '/admin_login', name: 'admin_login', component: () => import('../views/admin/AdminLoginView.vue') },
	{ path: '/admin', name: 'admin_dashboard', component: () => import('../views/admin/AdminDashboardView.vue'), meta: { requiresAuthAdmin: true },
		children: [
			{ path: 'config', component: () => import('../views/admin/config/ConfigView.vue'), meta: { requiresAuthAdmin: true }},
			{ path: 'tax', component: () => import('../views/admin/tax/TaxListView.vue'), meta: { requiresAuthAdmin: true }},
			{ path: 'course', component: () => import('../views/admin/course/CourseListView.vue'), meta: { requiresAuthAdmin: true }},
			{ path: 'course_user', component: () => import('../views/admin/course_user/CourseUserListView.vue'), meta: { requiresAuthAdmin: true }},
			{ path: 'course_add', component: () => import('../views/admin/course_add/CourseAddListView.vue'), meta: { requiresAuthAdmin: true }},
			{ path: 'car', component: () => import('../views/admin/car/CarListView.vue'), meta: { requiresAuthAdmin: true }},
			{ path: 'user', component: () => import('../views/admin/user/UserListView.vue'), meta: { requiresAuthAdmin: true }},
			{ path: 'shift_list', component: () => import('../views/admin/shift/ShiftListView.vue'), meta: { requiresAuthAdmin: true }},
			{ path: 'shift_template_list', component: () => import('../views/admin/shift_template/ShiftTemplateListView.vue'), meta: { requiresAuthAdmin: true }},
			{ path: 'shift_pattern', component: () => import('../views/admin/shift_pattern/ShiftPatternListView.vue'), meta: { requiresAuthAdmin: true }},
			{ path: 'payslip', component: () => import('../views/admin/payslip/PayslipListView.vue'), meta: { requiresAuthAdmin: true }},
			{ path: 'holiday_request', component: () => import('../views/admin/holiday_request/HolidayRequestListView.vue'), meta: { requiresAuthAdmin: true }},
			{ path: 'paid_grand_list', component: () => import('../views/admin/paid_grand/PaidGrandListView.vue'), meta: { requiresAuthAdmin: true }},
			{ path: 'permission', component: () => import('../views/admin/user/UserPermissionListView.vue'), meta: { requiresAuthAdmin: true }},
			{ path: 'work', component: () => import('../views/admin/work/WorkListView.vue'), meta: { requiresAuthAdmin: true }},
			{ path: 'schedule', component: () => import('../views/admin/schedule/ScheduleListView.vue'), meta: { requiresAuthAdmin: true }},
			{ path: 'premium', component: () => import('../views/admin/premium/PremiumListView.vue'), meta: { requiresAuthAdmin: true }},
			{ path: 'imcome', component: () => import('../views/admin/imcome/ImcomeListView.vue'), meta: { requiresAuthAdmin: true }},
			{ path: 'holiday', component: () => import('../views/admin/holiday/HolidayListView.vue'), meta: { requiresAuthAdmin: true }},
		]
	},
	{ path: '/employee_login', name: 'employee_login', component: () => import('../views/employee/EmployeeLoginView.vue') },
	{ path: '/employee_lostpassword', name: 'employee_lostpassword', component: () => import('../views/employee/EmployeeLostPasswordView.vue') },
	{ path: '/employee_passwordreset', name: 'employee_passwordreset', component: () => import('../views/employee/EmployeePasswordResetView.vue') },
	{ path: '/employee', component: () => import('../views/employee/EmployeeDashboardView.vue'), meta: { requiresAuthEmployee: true }},
	{ path: '/employee/shift_list', component: () => import('../views/employee/shift/ShiftListView.vue'), meta: { requiresAuthEmployee: true }},
	{ path: '/employee/holiday_request', component: () => import('../views/employee/holiday_request/HolidayRequestListView.vue'), meta: { requiresAuthEmployee: true }},
	{ path: '/employee/work_list', component: () => import('../views/employee/work/WorkListView.vue'), meta: { requiresAuthEmployee: true }},
	{ path: '/employee/work_edit/:work_no', component: () => import('../views/employee/work/WorkEditView.vue'), meta: { requiresAuthEmployee: true }},
	{ path: '/employee/schedule_list', component: () => import('../views/employee/schedule/ScheduleListView.vue'), meta: { requiresAuthEmployee: true }},
	{ path: '/employee/payslip', component: () => import('../views/employee/payslip/PayslipListView.vue'), meta: { requiresAuthEmployee: true }},
]

const router = createRouter({
	history: createWebHashHistory(),
	routes
})

router.beforeEach((to) => {
	if (to.meta.requiresAuthAdmin && !(store.getters.isAuthenticated && store.getters.isAdmin)) {
		store.commit('removeToken')
		return { name: "admin_login" };
	}
	if (to.meta.requiresAuthEmployee && !store.getters.isAuthenticated) {
		store.commit('removeToken')
		return { name: "employee_login" };
	}
});

export default router
